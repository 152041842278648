<template>
  <div class='flex flex-col lg:flex-row items-stretch min-h-screen'>
    <div
      class='w-full lg:w-80 px-8 pt-2 pb-8 lg:py-16 text-gray-50 flex flex-col items-start justify-start relative'
      :style='backgroundStyle'>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToMain'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Back to Conference Site
      </h1>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToRegistrationGuideline'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Guideline for Registration
      </h1>
      <h1 class='text-2xl lg:text-3xl font-light lg:mt-4'>Registration</h1>
    </div>
    <div class='p-8 lg:p-12 overflow-y-auto flex-grow'>
      <div v-if='isKorean'
        class='mb-8'>
        <h2 class='text-2xl font-semibold text-gray-900 mb-4'>Who would you like to register?</h2>
        <div class='flex flex-row justify-start items-center gap-x-2 text-sm uppercase'>
          <label class='radio-for-who-to-register border rounded-md w-60 py-6 text-center cursor-pointer hover:shadow-md' :class='selectedRegisteredFor("myself")'>
            <input type='radio' v-model='registrationFor' value='myself' class='hidden' />
            Myself (개인등록)
          </label>
          <label v-if='isKorean'
            class='radio-for-who-to-register border rounded-md w-60 py-6 text-center cursor-pointer hover:shadow-md' :class='selectedRegisteredFor("others")'>
            <input type='radio' v-model='registrationFor' value='others' class='hidden' />
            단체등록
          </label>
        </div>
      </div>
      <registration-for-myself-only v-if='registeringJustMyself && !alreadyRegisteredMyself' class='max-w-2xl' />
      <registration-done-message v-if='registeringJustMyself && alreadyRegisteredMyself'
       class='max-w-2xl bg-gray-100 rounded-md py-16'
       :is-korean='isKorean' />
      <div v-if='registeringOthers'
        class='max-w-2xl'>
        <div class='mb-24'>
          <h1 class='text-2xl font-semibold text-gray-900'>Already Registered</h1>
          <h3 class='text-sm lg:text-base text-gray-500 uppercase mb-8'>Participants that you have registered</h3>
          <table v-if='hasAlreadyRegisteredAttendees' class='w-full text-sm'>
            <thead>
              <tr class='uppercase border-b'>
                <th class='text-left'>Attendee</th>
                <th class='text-right'>Registration Fee</th>
              </tr>
            </thead>
              <tbody>
                <tr v-for='(registeredAttendee, index) in registeredAttendees'
                  :key='`registeredAttendee-${index}`'
                  class='border-b'>
                  <td class='pr-2 py-2 text-left'>
                    {{registeredAttendee.profile.firstName}} {{registeredAttendee.profile.lastName}} <span class='text-gray-600' v-if='registeredAttendee.me'>(me)</span><br>
                    <span class='text-gray-600'>({{registeredAttendee.email}})</span>
                  </td>
                  <td class='pl-2 py-2 text-right'>{{registeredAttendee.registrationData.registrationFee}}<br>({{registeredAttendee.registrationData.registrationType}})</td>
                </tr>
              </tbody>
          </table>
          <div v-else
            class='flex flex-col items-center justify-center bg-gray-100 rounded-md py-16'>
            <p class='text-gray-600 px-4'>You have not completed registration for any attendees.</p>
          </div>
        </div>
        <div class='mb-24'>
          <h1 class='text-2xl font-semibold text-gray-900'>New Registrations</h1>
          <h3 class='text-sm lg:text-base text-gray-500 uppercase mb-8'>Participants that you are registering</h3>
          <div v-if='hasRegisteringAttendees'>
            <table class='w-full text-sm mb-8'>
              <thead>
                <tr class='uppercase border-b'>
                  <th class='text-left'>Attendee</th>
                  <th class='text-left'>Status</th>
                  <th class='text-right'>Registration Fee</th>
                  <th class='w-16'></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='(attendee, index) in registeringAttendees'
                  :key='`registering-attendee-${index}`'
                  class='border-b cursor-pointer'
                  :class='selectedAttendeeClass(index)'
                  @click='openRegistration(attendee, index)'>
                  <td class='py-2'>
                    {{attendee.profile.firstName}} {{attendee.profile.lastName}} <span class='text-gray-600' v-if='attendee.me'>(me)</span><br>
                    <span class='text-gray-600'>({{attendee.email}})</span>
                  </td>
                  <td class='py-2 uppercase'>{{attendeeStatusString(attendee)}}</td>
                  <td class='py-2 text-right'>{{attendeeRegistrationFeeString(attendee)}}<br>({{attendeeRegistrationTypeString(attendee)}})</td>
                  <td @click.stop='' class='text-right py-2'>
                    <button @click='removeAttendee(index, attendee.ssoIdentityId)' class='px-2 py-2 rounded-md border border-gray-300'><trash-icon class='h-5' /></button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td colspan='4' class='py-2'>
                    <button class='w-full py-2 rounded-md border border-gray-500 hover:shadow-md uppercase text-sm' @click='addAttendee'>Add Attendee</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class='border rounded-t-md'>
              <div class='bg-gray-100 text-gray-900 px-4 py-6 uppercase font-semibold flex flex-row justify-between items-center'>
                <span>Total Amount Due (KRW):</span>
                <span>{{totalAmountDue}}</span>
              </div>
            </div>
            <div class='border-l border-r border-b bg-gray-50'>
              <div class='flex flex-row justify-between items-center px-4 py-2'>
                <div class='uppercase'>Payment Method</div>
                <select
                  v-model='paymentGateway'
                  class='border solid rounded-md px-2 py-3'>
                  <option value='iamport-nice'>Korean Domestic Card (신용카드)</option>
                </select>
              </div>
              <div v-if='isPaymentGatewayBankTransfer'>
                <div class='flex flex-row justify-between items-center border-t mx-4 pt-2 pb-4'>
                  <label class='uppercase'>입금자명</label>
                  <input
                    v-model='depositor'
                    class='border py-2 px-2 rounded block w-64'
                    type='text'
                    placeholder='입금자명'>
                </div>
                <div class='flex flex-row justify-between items-center border-t mx-4 pt-2 pb-4'>
                  <label class='uppercase'>입금예정일</label>
                  <date-picker
                    class='w-auto date-picker-auto-width'
                    input-class='border py-2 px-2 rounded block w-64'
                    v-model="expectedDepositDate"
                    valueType="format"
                    placeholder='입금예정일'>
                  </date-picker>
                </div>
              </div>
            </div>
            <div class='px-4 py-8 bg-gray-50 border-l border-r border-b rounded-b-md mb-8'>
              <h1 class='text-base font-semibold mb-4 text-gray-700 uppercase'>Cancellation & Refund Policy</h1>
              <ul class='list-disc pl-4 text-sm'>
                <li>Cancellations must be notified to the secretariat in writing by email to: <a href='mailto:ortho@koa.or.kr' class='underline text-blue-700'>ortho@koa.or.kr</a></li>
                <li>All refunds will be made after the congress.</li>
                <li>All bank service charges and all administration fees will be deducted from all congress registration refunds.</li>
                <li>Please refer to the following cut off dates for cancellation.</li>
              </ul>
            </div>
            <button
              :disabled='disabledRegistrationButton'
              @click='saveDataAndRegister'
              class='border py-4 px-8 text-center text-white text-sm uppercase w-full block rounded-md'
              :style='`background-color: ${eventMainThemeColor};`'
              :class='disabledRegistrationButtonClass'>
              Register & Pay Registration Fees
            </button>
            <ul class='list-disc list-inside mt-8 text-red-600 text-xs'>
              <li v-if='isPaymentGatewayBankTransfer && emptyDepositor'>입금자명을 입력해주세요.</li>
              <li v-if='isPaymentGatewayBankTransfer && emptyExpectedDepositDate'>입금예정일을 입력해주세요.</li>
            </ul>
          </div>
          <div v-else
            class='flex flex-col items-center justify-center bg-gray-100 rounded-md py-16'>
            <p class='text-gray-600 px-4'>There are no registering attendees.</p>
            <button class='font-semibold rounded-md underline hover:bg-gray-200 p-2' @click='addAttendee'>Add Attendee</button>
          </div>
        </div>
      </div>
    </div>
    <sidepanel><portal-target name='sidepanel'></portal-target></sidepanel>
    <modal
      name='registration-payment-modal-batch'
      :adaptive='true'
      :click-to-close='canClickToCloseRegistrationModal'
      height='auto'
      :min-width='registrationModalMinWidth'
      :scrollable='true'
      classes='registration-payment-modal-batch relative'
      @opened='initializePaymentModal'
      @closed='redirectIfFinished'>
      <button v-if='canClickToCloseRegistrationModal'
        class='z-30 absolute top-3 right-3' 
        @click="$modal.hide('registration-payment-modal-batch')">
        <x-icon class='text-black' />
      </button>
      <div v-if='registrationPaymentModalComponent == "bank-transfer"'>
        <div class='p-8 lg:p-16 overflow-y-auto flex-grow'>
          <div class='max-w-2xl'>
            <h1 class='text-3xl font-semibold mb-8'>등록이 접수되었습니다</h1>
            <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
              <ul class='list-disc'>
                <li class='py-1'>등록비: {{totalAmountDue}}원</li>
                <li class='py-1'>등록비 납부는 온라인 사전등록을 완료하신 후 반드시 입금자 성함으로 송금해 주시고 현금영수증은 성함, 입금일, 현금영수증 발행번호를 작성하여 학회 이메일 (<a href='mailto:ortho5@koa.or.kr' class='underline' :style='themeTextColorStyle'>ortho5@koa.or.kr</a>)로 요청하시기 바랍니다.</li>
                <li class='py-1'>등록 내용은 접수완료 메일을 확인해 주시고 등록비 납부가 확인되면 최종 등록 완료 메일이 발송됩니다.</li>
                <li class='py-1'>학회 계좌 정보 : <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>KB국민은행 269101-04-008077</span>, 예금주 대한정형외과학회</li>
              </ul>
            </div>
            <router-link :to='{name: "Home"}' class='block mt-8 w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>홈으로 가기</router-link>
          </div>
        </div>
      </div>
      <iamport-payment v-if='registrationPaymentModalComponent == "iamport-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='false'
        :is-korean='isKorean'
        @payment-success='iamportSuccess'
        @payment-fail='paymentFail' />
      <payment-fail v-if='registrationPaymentModalComponent == "payment-fail"'
        :is-modal-payment='true'
        :is-korean='isKorean' />
      <payment-success-batch v-if='registrationPaymentModalComponent == "payment-success-batch"'
        :payment-params-prop='paymentSuccessParams'
        :is-modal-payment='true'
        :is-korean='isKorean'
        @payment-finished='paymentFinished'
        @payment-fail='paymentFail' />
      <registration-finished-batch v-if='registrationPaymentModalComponent == "registration-finished-batch"'
        :payment-params-prop='paymentFinishedParams'
        :is-modal-payment='true'>
      </registration-finished-batch>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'
import { TrashIcon, XIcon } from '@vue-hero-icons/outline'
import isEmpty from 'lodash/isEmpty'
import Sidepanel                 from '@/components/Sidepanel'
import RegistrationForMyselfOnly from '@/components/registration/RegistrationForMyselfOnly.vue'
import IamportPayment            from '@/components/iamport-payment/IamportPayment.vue'
import RegistrationDoneMessage   from '@/components/iamport-payment/RegistrationDoneMessage.vue'
import PaymentFail               from '@/components/payments/PaymentFail.vue'
import PaymentSuccessBatch       from '@/components/payments/PaymentSuccessBatch.vue' // need to do special stuff for batch orders
import RegistrationFinishedBatch from '@/components/payments/RegistrationFinishedBatch.vue'
import EventBus                  from '@/utils/EventBus'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Registration',
  components: {
    Sidepanel,
    RegistrationForMyselfOnly,
    IamportPayment,
    RegistrationDoneMessage,
    PaymentFail,
    PaymentSuccessBatch,
    RegistrationFinishedBatch,
    TrashIcon,
    XIcon,
    ArrowNarrowLeftIcon,
    DatePicker,
},
  data () {
    return {
      registrationFor: '',
      selectedAttendeeIndex: -1,
      depositor: '',
      expectedDepositDate: '',
      paymentGateway: 'iamport-nice',
      registrationPaymentModalComponent: '',
      allAttendeesCompleteInfo: false,
      paymentParams: null,
      paymentSuccessParams: null,
      paymentFailParams: null,
      paymentFinishedParams: null,
      totalAmountDue: 0
    }
  },
   watch: {
    'isKorean': {
      handler: function (newVal) {
        if (newVal) {
          this.paymentGateway = 'iamport-nice'
        } else {
          this.paymentGateway = 'iamport-eximbay'
          this.registrationFor = 'myself'
        }
      },
      immediate: true 
    },
    registrationFor: {
      handler: function (newVal) {
        if (newVal === 'myself') {
          this.sidepanelClose()
        }
        if (newVal === 'others') {
          this.getRegisteredByMe().then(() => {
            if (this.registeringAttendees.length === 0) {
              this.addAttendee()
            }
            this.totalAmountDue = this.registeringAttendees.map(attendee => {
              return (attendee.registrationProduct && attendee.registrationProduct.price.amount) ? attendee.registrationProduct.price.amount : 0
            }).reduce((a, b) => a + b, 0)
          })
        }
      },
      immediate: true
    },
    selectedAttendeeIndex: {
      handler: function (newVal, oldVal) {
        if (newVal == -1 && newVal !== oldVal) { // do a check when closing sidepanel
          this.allAttendeesCompleteInfo = this.checkAllAttendeeReadyStatus()
          this.totalAmountDue = this.registeringAttendees.map(attendee => {
            return (attendee.registrationProduct && attendee.registrationProduct.price.amount) ? attendee.registrationProduct.price.amount : 0
          }).reduce((a, b) => a + b, 0)
        }
      }
    },
    'registeringAttendees.length': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.allAttendeesCompleteInfo = this.checkAllAttendeeReadyStatus()
          this.totalAmountDue = this.registeringAttendees.map(attendee => {
            return (attendee.registrationProduct && attendee.registrationProduct.price.amount) ? attendee.registrationProduct.price.amount : 0
          }).reduce((a, b) => a + b, 0)
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('users', [
      'profile',
      'registrationData',
      'registeringAttendees',
      'registeredAttendees',
      'editingAttendee',
    ]),
    ...mapState('products',[
      'products'
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId',
      'themeTextColorStyle',
    ]),
    ...mapFields('products', [
      'registrationProduct',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    alreadyRegisteredMyself () {
      return this.registrationData.payment
    }, 
    hasAlreadyRegisteredAttendees () {
      return this.registeredAttendees.length > 0
    },
    hasRegisteringAttendees () {
      return this.registeringAttendees.length > 0
    },
    registeringJustMyself () {
      return this.registrationFor === 'myself'
    },
    registeringOthers () {
      return this.registrationFor === 'others'
    },
    isKorean () {
      return !this.profile.country ||
             this.profile.country.toLowerCase() === 'kr'
    },
    isPaymentGatewayBankTransfer () {
      return this.paymentGateway === 'bank_transfer'
    },
    disabledRegistrationButton () {
      if (this.isPaymentGatewayBankTransfer) {
        return !this.allAttendeesCompleteInfo || this.emptyDepositor || this.emptyExpectedDepositDate
      } else {
        return !this.allAttendeesCompleteInfo
      }
    },
    disabledRegistrationButtonClass () {
      return this.disabledRegistrationButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    canClickToCloseRegistrationModal () {
      return this.registrationPaymentModalComponent == 'payment-fail'
    },
    registrationModalMinWidth () {
      return 700
    },
    emptyDepositor () {
      return !this.depositor
    },
    emptyExpectedDepositDate () {
      return !this.expectedDepositDate
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
      'getMembershipData',
      'getRegistrationData',
      'getTerms',
      'getRegisteredByMe',
      'addRegisteringAttendee',
      'editAttendee',
      'resetEditingAttendee',
      'registrationDataBatchUpdate',
      'registrationDataReset',
    ]),
    ...mapMutations('users', [
      'removeAttendeeAtIndex',
    ]),
    ...mapActions('products', [
      'getAllProducts',
    ]),
    ...mapActions('orders', [
      'createOrder',
    ]),
    ...mapActions('registrations', [
      'sendRegistrationEmails',
    ]),
     ...mapActions([
      'sidepanelOpen',
      'sidepanelClose',
    ]),
    removeAttendee(index, ssoIdentityId) {
      if (this.selectedAttendeeIndex == index) {
        this.resetEditingAttendee()
        this.selectedAttendeeIndex = -1
        this.sidepanelClose()
      }
      if (ssoIdentityId) {
        this.registrationDataReset({ssoIdentityId: ssoIdentityId})
      } else {
        this.removeAttendeeAtIndex(index)
      }
    },
    openRegistration (whichAttendee, index) {
      this.editAttendee(whichAttendee)
      this.selectedAttendeeIndex = index
      this.sidepanelOpen({ componentName: 'register-new-form-fields' })
    },
    selectedRegisteredFor (val) {
      return (this.registrationFor === val) ? 'selected' : ''
    },
    selectedAttendeeClass (index) {
      return this.selectedAttendeeIndex === index ? 'bg-blue-100' : 'bg-transparent'
    },
    addAttendee () {
      this.addRegisteringAttendee()
      let index = this.registeringAttendees.length - 1
      let attendee = this.registeringAttendees[index]
      this.openRegistration(attendee, index)
    },
    checkAllAttendeeReadyStatus () {
      let updatedBool = this.registeringAttendees.every(attendee => {
          return attendee.userId && attendee.registrationProduct
        })
      return updatedBool
    },
    attendeeStatusString (attendee) {
      if (attendee.userId && attendee.registrationProduct) {
        if (attendee.registrationData.paymentGateway === 'bank_transfer') {
          return 'Waiting For Deposit'
        } else {
          return 'Ready For Payment'
        }
      } else {
        return 'Incomplete'
      }
    },
    attendeeRegistrationFeeString (attendee) {
      return (attendee.registrationProduct && !isEmpty(attendee.registrationProduct.price)) ? `${attendee.registrationProduct.price.priceUnit} ${attendee.registrationProduct.price.amount}` : ''
    },
    attendeeRegistrationTypeString (attendee) {
      return (attendee.registrationProduct && !isEmpty(attendee.registrationProduct.name)) ? `${attendee.registrationProduct.name}` : ''
    },
    saveDataAndRegister () {
      let registrationsData = []
      let orders = []
      let promise = this.registeringAttendees.map(attendee => {
        return new Promise((resolve, reject) => {
          let paymentStatus = false
          let paidAt = ''
          this.createOrder({product: {
            user_id: attendee.userId,
            product_id: attendee.registrationProduct.id,
            payment_type: this.paymentGateway,
            event_id: this.showingEventId}
          }).then(order => {
            orders.push(order)
            if (order.status === 'Done') {
              //무료인 사람들 (65세 이상)
              paidAt = new Date()
              paymentStatus = true
            }
            registrationsData.push({
              email: attendee.email,
              registrationData: {
                paidAt: paidAt,
                payment: paymentStatus,
                depositor: this.depositor,
                expectedDepositDate: this.expectedDepositDate,
                paymentGateway: this.paymentGateway,
                registrationNumber: `G-${this.profile.ssoIdentityId}`
              }
            })
            resolve(order)
          }).catch((error) => {
            reject(error)
          })
        })
      })
      Promise.all(promise).then(() => {
        this.registrationDataBatchUpdate({registrationsData: registrationsData}).then(() => {
          if (this.isPaymentGatewayBankTransfer) {
            this.sendRegistrationEmails({ email_type: 'group_pending', registering_attendees: this.registeringAttendees.map((attendee) => attendee.email) })
            this.$modal.show('registration-payment-modal-batch')
          } else if (this.paymentGateway === 'iamport-nice') {
            this.paymentParams = {
              pg:           this.paymentGateway,
              currency:     'KRW',
              amount:       this.totalAmountDue,
              orderId:      orders.map(order => order.hashedOrderId).join(','),
              orderName:    this.registeringAttendees.map(attendee => attendee.registrationProduct.name).join(','),
              customerName: this.profile.name ,
              customerPhoneNumber: this.profile.phoneNumber,
            }
            this.$modal.show('registration-payment-modal-batch')
          }
        })
      }).catch((error) => {
        console.error(error)
        this.$alert('Your payment could not be processed. If you continue to have problems, please contact support.', {
          type: 'warning'
        })
      })
    },
    initializePaymentModal () {
      if (this.isPaymentGatewayBankTransfer) {
        this.registrationPaymentModalComponent = 'bank-transfer'
      } else {
        this.registrationPaymentModalComponent = 'iamport-payment'
      }
    },
    paymentFail (params) {
      // show payment fail with params
      this.paymentFailParams = params
      this.registrationPaymentModalComponent = 'payment-fail'
    },
    iamportSuccess (params) {
      // show payment success with params
      this.paymentSuccessParams = params
      this.registrationPaymentModalComponent = 'payment-success-batch'
    },
    paymentFinished (params) {
      // show payment finished with params
      this.paymentFinishedParams = params
      this.registrationPaymentModalComponent = 'registration-finished-batch'
      // Payment done
      let paidDataParams = {
        registrationsData: this.registeringAttendees.filter(attendee => attendee.registrationProduct.price.amount).map(attendee => {
          return {
            email: attendee.email,
            registrationData: {
              payment: true,
              paidAt: new Date(),
            }
          }
        })
      }
      this.registrationDataBatchUpdate(paidDataParams).then(() => {
        this.sendRegistrationEmails({ email_type: 'group_confirm', registering_attendees: this.registeringAttendees.map((attendee) => attendee.email) })
      })
    },
    redirectIfFinished () {
      if (this.registrationPaymentModalComponent == 'registration-finished-batch') {
        this.redirectAfterAction()
      }
    },
    goToMain () {
      this.$router.push({name: 'Main'})
    },
    goToRegistrationGuideline () {
      this.$router.push({name: 'RegistrationGuideline'})
    },
  },
  created () {
    this.getProfile()
    this.getMembershipData()
    this.getRegistrationData()
    this.getTerms()
    this.getAllProducts()
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    EventBus.$on('sidepanel-closed', () => {
      this.resetEditingAttendee()
      this.selectedAttendeeIndex = -1
    })
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
    EventBus.$off('sidepanel-closed')
  },
}
</script>

<style lang='scss'>
.date-picker-auto-width.mx-datepicker {
  width: auto !important;
}
</style>

<style lang='scss' scoped>
.radio-for-who-to-register.selected,
.location-radio-label.selected {
  @apply text-white;
  background-color: var(--eventMainColor);
}
</style>
